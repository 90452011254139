<template>
    <header class="page-title">
        <h1><i class="bi bi-people"></i> 案件詳細 - {{ project.customer.company_name }}</h1>
        <router-link :to="{name: 'Project'}" class="btn btn-outline-primary"><i class="bi bi-reply"></i> 案件一覧へ</router-link>
    </header>

    <inline-loader v-if="loading"></inline-loader>
    <template v-else>
        <!-- ヘッダ -->
        <ProjectHeader
            :project="project"
            :options_certainty="options_certainty"
            :options_department="options_department"
            :options_user="options_user"
            :options_competitor="options_competitor"
            :is_editing_detail="is_editing_detail"
            :is_editing_customer="is_editing_customer"
            @update="render"
            @updateOptionsProbability="updateOptionsProbability"
            @updateOptionsDetailedRank="updateOptionsDetailedRank"
            @updateOptionsCertainty="updateOptionsCertainty"
            @updateOptionsCompetitor="updateOptionsCompetitor"
            @changeIsEditHeader="changeIsEditHeader"
        ></ProjectHeader>

        <div class="row">
            <div class="col-24">
                <!-- 顧客情報 編集・閲覧 -->
                <ProjectCustomer
                    :project="project"
                    :is_editing_header="is_editing_header"
                    @update="render"
                    @changeIsEditCustomer="changeIsEditCustomer"
                ></ProjectCustomer>

                <!-- 案件情報 編集・閲覧 -->
                <ProjectDetail
                    :project="project"
                    :options_department="options_department"
                    :options_user="options_user"
                    :options_user_object="options_user_object"
                    :options_channel="options_channel"
                    :options_area="options_area"
                    :options_detailed_channel="options_detailed_channel"
                    :options_probability="options_probability"
                    :options_detailed_rank="options_detailed_rank"
                    :options_certainty="options_certainty"
                    :options_competitor="options_competitor"
                    :is_editing_header="is_editing_header"
                    @update="render"
                    @updateOptionsProbability="updateOptionsProbability"
                    @updateOptionsDetailedRank="updateOptionsDetailedRank"
                    @updateOptionsCertainty="updateOptionsCertainty"
                    @updateOptionsCompetitor="updateOptionsCompetitor"
                    @changeIsEditDetail="changeIsEditDetail"
                ></ProjectDetail>

                <!-- ファイル -->
                <ProjectFile
                    :project="project"
                ></ProjectFile>

                <!-- ファイル -->
                <section class="section" v-if="$store.getters['auth/canManageUser']">
                    <div class="text-end">
                        <button @click="this.$refs.confirmRemove.show();" type="button" class="btn btn-outline-danger p-2"><i class="bi bi-trash me-2"></i>案件削除</button>
                    </div>
                </section>
            </div>

            <div class="col-12">

                <!-- 活動履歴 -->
                <ProjectActivityHistory
                    v-if="project.activities.length"
                    :project="project"
                    :options_user="options_user"
                    :options_detailed_rank="options_detailed_rank"
                    :options_document="options_document"
                    :options_probability="options_probability"
                    :options_certainty="options_certainty"
                    :options_competitor="options_competitor"
                    :options_appointment_source="options_appointment_source"
                    :options_area="options_area"
                    @update="render"
                    @remove="render"
                ></ProjectActivityHistory>

                <!-- 重複疑い -->
                <ProjectDuplicate
                    v-if="duplicates.length && $store.getters['auth/canEditProject']"
                    :project="project"
                    :duplicates="duplicates"
                    @update="render"
                    @remove_duplicate="removeDuplicate"
                ></ProjectDuplicate>

                <!-- フィードバック -->
                <ProjectFeedback
                    :project="project"
                    @save="render"
                ></ProjectFeedback>

                <!-- 変更履歴 -->
                <ProjectChangeHistory
                    v-if="project.histories.length"
                    :project="project"
                ></ProjectChangeHistory>

            </div>
        </div>

        <!-- 活動登録 -->
        <div class="drawer" :class="{'drawer-show': drawer_show}">
            <div class="drawer-handle" @click="drawer_show = !drawer_show">
                <template v-if="drawer_show">
                    <div class="h1"><i class="bi bi-x"></i></div>
                    <div class="small">閉じる</div>
                </template>
                <template v-else>
                    <div class="h1"><i class="bi bi-plus"></i></div>
                    <div class="small">活動登録</div>
                </template>
            </div>
            <ProjectActivityAdd
                v-if="$store.getters['auth/canEditProject']"
                :project="project"
                :options_user="options_user"
                :options_user_object="options_user_object"
                :options_area="options_area"
                :options_detailed_rank="options_detailed_rank"
                :options_document="options_document"
                :options_probability="options_probability"
                :options_certainty="options_certainty"
                :options_competitor="options_competitor"
                :options_appointment_source="options_appointment_source"
                @save="render"
            ></ProjectActivityAdd>
        </div>

    </template>

    <confirm-dialog ref="confirmRemove" @ok="remove">
        <p>本当に削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';
import Project from '@/models/entities/project';
import InlineLoader from '@/components/tools/InlineLoader';
import ProjectHeader from '@/components/projects/ProjectHeader';
import ProjectCustomer from '@/components/projects/ProjectCustomer';
import ProjectDetail from '@/components/projects/ProjectDetail';
import ProjectActivityAdd from '@/components/projects/ProjectActivityAdd';
import ProjectActivityHistory from '@/components/projects/ProjectActivityHistory';
import ProjectFeedback from '@/components/projects/ProjectFeedback';
import ProjectDuplicate from '@/components/projects/ProjectDuplicate';
import ProjectChangeHistory from '@/components/projects/ProjectChangeHistory';
import ProjectFile from '@/components/projects/ProjectFile';
import Department  from '@/models/entities/department';
import User  from '@/models/entities/user';
import Channel from '@/models/entities/channel';
import Competitor from '@/models/entities/competitor';
import Area from '@/models/entities/area';
import DetailedChannel from '@/models/entities/detailed-channel';
import AppointmentSource from '@/models/entities/appointment-source';
import Probability from '@/models/entities/probability';
import DetailedRank from '@/models/entities/detailed-rank';
import Certainty from '@/models/entities/certainty';
import Document from '@/models/entities/document';
import Duplicate from '@/models/entities/duplicate';

export default {
    name: 'ProjectEdit',
    components: {
        InlineLoader,
        ProjectHeader,
        ProjectCustomer,
        ProjectDetail,
        ProjectActivityAdd,
        ProjectActivityHistory,
        ProjectFeedback,
        ProjectDuplicate,
        ProjectChangeHistory,
        ProjectFile,
        ConfirmDialog,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
        'setTitle',
    ],
    data() {
        return {
            // ローディング
            loading: false,
            // パスパラメータproject_id
            param_project_id: parseInt(this.$route.params.id, 10),

            project: new Project(),

            // オブション一覧
            options_department: [],
            options_user: [],
            options_user_object: [],
            options_channel: [],
            options_area: [],
            options_detailed_channel: [],
            options_appointment_source: [],
            options_document: [],
            // 以下事業部によって変わるoption
            options_probability: [],
            options_detailed_rank: [],
            options_certainty: [],
            options_competitor: [],

            // 重複疑い
            duplicates: [],

            // 事業部変更時disabled表示切り替えフラグ
            is_disabled: false,

            // ヘッダー編集disabledフラグ
            is_editing_header: false,
            // 案件情報編集disabledフラグ
            is_editing_detail: false,
            // 顧客情報編集disabledフラグ
            is_editing_customer: false,

            drawer_show: false,
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            // パスパラメータから案件取得
            const is_find_project = await this.findProject();
            if (is_find_project) {
                // オプション一覧取得
                this.fetchOptions();
            }
        },
        findProject() {
            return new Promise((resolve, reject) => {
                this.loading = true;

                this.$http.get(`/project/${this.param_project_id}`)
                .then(response => {
                    this.project = new Project(response.data);
                    this.setTitle(this.project.customer.company_name);
                    resolve(true);
                })
                .catch((error) => {
                    this.showErrorMessage('案件が存在しません');
                    if (error.response.status === 404) {
                        this.$router.push({name: 'Project'});
                    }
                    reject(false);
                })
                .finally(() => {
                    this.loading = false;
                })
            })
        },

        // オプション取得
        async fetchOptions() {
            this.loading = true;

            await Promise.all([
                // 事業部
                this.fetchDepartments(),
                // ユーザ
                this.fetchUsers(),
                // チャネル
                this.fetchChannels(),
                // 都道府県
                this.fetchAreas(),
                // 送信元ページ
                this.fetchDetailedChannels(),
                // アポ取得
                this.fetchAppointmentSources(),
                // 資料
                this.fetchDocuments(),
                // 優先度
                this.fetchProbabilities(),
                // サブ評価
                this.fetchDetailedRanks(),
                // 確実度
                this.fetchCertainties(),
                // 競合
                this.fetchCompetitors(),
                // 重複疑い
                this.fetchDuplicates(),
            ]);

            this.loading = false;
        },

        // 事業マスタ取得
        fetchDepartments() {
            return new Promise(resolve => {
                this.$http.get('/master/department')
                .then(response => {
                    for (let row of response.data) {
                        let department = new Department(row);
                        this.options_department.push(department);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // ユーザマスタ取得
        fetchUsers() {
            return new Promise(resolve => {
                this.$http.get('/user')
                .then(response => {
                    for (let row of response.data) {
                        let user = new User(row);
                        this.options_user.push({value: user.user_id, label: user.user_name});
                        this.options_user_object.push(user);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // チャネルマスタ取得
        fetchChannels() {
            return new Promise(resolve => {
                this.$http.get('/master/channel')
                .then(response => {
                    for (let row of response.data) {
                        let channel = new Channel(row);
                        this.options_channel.push(channel);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // エリアマスタ取得
        fetchAreas() {
            return new Promise(resolve => {
                this.$http.get('/master/area')
                .then(response => {
                    for (let row of response.data) {
                        let area = new Area(row);
                        this.options_area.push(area);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // 送信元ページマスタ取得
        fetchDetailedChannels() {
            return new Promise(resolve => {
                this.$http.get('/master/detailed-channel')
                .then(response => {
                    for (let row of response.data) {
                        let detailed_channel = new DetailedChannel(row);
                        this.options_detailed_channel.push(detailed_channel);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // アポ取得リストマスタ取得
        fetchAppointmentSources() {
            return new Promise(resolve => {
                this.$http.get('/master/appointment-source')
                .then(response => {
                    for (let row of response.data) {
                        let appointment_source = new AppointmentSource(row);
                        this.options_appointment_source.push(appointment_source);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // 資料マスタ取得
        fetchDocuments() {
            return new Promise(resolve => {
                this.$http.get('/master/document')
                .then(response => {
                    for (let row of response.data) {
                        let document = new Document(row);
                        this.options_document.push({value: document.document_id, label: document.document_name});
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // 優先度マスタ取得
        fetchProbabilities() {
            return new Promise(resolve => {
                this.options_probability.splice(0);

                this.$http.get('/master/probability', {
                    params: {
                        department: this.project.department.department_id,
                    }
                })
                .then(response => {
                    for (let row of response.data) {
                        let probability = new Probability(row);
                        this.options_probability.push(probability);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // サブ評価マスタ取得
        fetchDetailedRanks() {
            return new Promise(resolve => {
                this.options_detailed_rank.splice(0);

                this.$http.get('/master/detailed-rank', {
                    params: {
                        department: this.project.department.department_id,
                    }
                })
                .then(response => {
                    for (let row of response.data) {
                        let detailed_rank = new DetailedRank(row);
                        this.options_detailed_rank.push(detailed_rank);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // 確実度マスタ取得
        fetchCertainties() {
            return new Promise(resolve => {
                this.options_certainty.splice(0);

                this.$http.get('/master/certainty', {
                    params: {
                        department: this.project.department.department_id,
                    }
                })
                .then(response => {
                    for (let row of response.data) {
                        let certainty = new Certainty(row);
                        this.options_certainty.push(certainty);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // 競合マスタ取得
        fetchCompetitors() {
            return new Promise(resolve => {
                this.options_competitor.splice(0);

                this.$http.get('/master/competitor', {
                    params: {
                        department: this.project.department.department_id,
                    }
                })
                .then(response => {
                    for (let row of response.data) {
                        let competitor = new Competitor(row);
                        this.options_competitor.push(competitor);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // 重複疑い取得
        fetchDuplicates() {
            return new Promise(resolve => {
                this.duplicates.splice(0);

                this.$http.get(`/project/${this.project.project_id}/duplicate`)
                .then(response => {
                    for(let row of response.data) {
                        this.duplicates.push(new Duplicate(row));
                    }
                })
                .finally(() => {
                    resolve(true);
                });
            });
        },

        updateOptionsProbability(new_options_probability) {
            this.options_probability = new_options_probability;
        },

        updateOptionsDetailedRank(new_options_detailed_rank) {
            this.options_detailed_rank = new_options_detailed_rank;
        },

        updateOptionsCertainty(new_options_certainty) {
            this.options_certainty = new_options_certainty;
        },

        updateOptionsCompetitor(new_options_competitor) {
            this.options_competitor = new_options_competitor;
        },

        // 再描画
        render(updated_project) {
            this.project = new Project(updated_project);
            this.setTitle(this.project.customer.company_name);
        },
        // 統合した重複疑い削除
        removeDuplicate(remove_index) {
            this.duplicates.splice(remove_index, 1);
        },
        // ヘッダーの編集中Booleanの変更イベント
        changeIsEditHeader(bool) {
            if (bool) {
                this.is_editing_header = true;
            } else {
                this.is_editing_header = false;
            }
        },
        // 案件情報の編集中Booleanの変更イベント
        changeIsEditDetail(bool) {
            if (bool) {
                this.is_editing_detail = true;
            } else {
                this.is_editing_detail = false;
            }
        },
        // 顧客情報の編集中Booleanの変更イベント
        changeIsEditCustomer(bool) {
            if (bool) {
                this.is_editing_customer = true;
            } else {
                this.is_editing_customer = false;
            }
        },
        // 案件削除
        remove() {
            this.startScreenLoading();

            this.$http.delete(`/project/${this.project.project_id}`)
            .then(() => {
                this.showMessage('削除しました');
                // 案件一覧へ遷移
                this.$router.push({name: 'Project'});
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
    }
}
</script>

<style scoped>
    .row { align-items: stretch; }
    .section div.row>* {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .drawer {
        position: fixed;
        z-index: 99900;
        right: -360px;
        top: 0;
        width: 360px;
        height: 100vh;
        padding: 10px;
        overflow-y: scroll;
        background: #FFF;
        border-left: 2px solid #3AC;
        box-shadow: -1px 0 2px rgba(0,0,0,0.5);
        transition: right 0.3s;
    }
    .drawer-handle {
        position: fixed;
        bottom: 20px;
        right: 0;
        padding: 10px;
        text-align: center;
        color: #FFF;
        background: #3AC;
        border-radius: 10px 0 0 10px;
        box-shadow: -1px 0 2px rgba(0,0,0,0.5);
        transition: right 0.3s;
    }
    .drawer.drawer-show {
        right: 0;
    }
    .drawer-show .drawer-handle {
        right: 360px;
    }
</style>
