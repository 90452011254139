import Enum from './enum'

/**
 * 計測カウント
 */
class CountType extends Enum {
    static NONE = 1;
    static DUPLICATE_COUNT = 2;
    static DUPLICATE = 3;
    static GARBAGE = 4;
    static DECLINE = 5;
    static EXCLUDE_NO_COUNT = 99;

    static values() {
        return {
            [this.NONE]: '重複なし',
            [this.DUPLICATE_COUNT]: '重複（カウントする）',
            [this.DUPLICATE]: '重複（カウントしない）',
            [this.GARBAGE]: 'クズ（カウントしない）',
            [this.DECLINE]: 'お断り (カウントするがアポ率計算除外)',
        }
    }

    static optionsForSearch() {
        let options = this.options();
        options.unshift({
            value: [this.EXCLUDE_NO_COUNT],
            label: '全て（カウント外除く）',
        })

        return options;
    }
}

export default CountType;
