class SearchQueryService
{
    static query_keys = {
        kw: 'keyword',
        d: 'department_in',
        do: 'department_operator',
        cu: 'contact_user_id',
        fu: 'follow_user_id',
        su: 'sales_user_id',
        a: 'area_id',
        s: 'status_in',
        so: 'status_operator',
        c: 'certainty_in',
        co: 'certainty_operator',
        p: 'probability_in',
        po: 'probability_operator',
        r: 'rank_in',
        ro: 'rank_operator',
        dr: 'detailed_rank_in',
        dro: 'detailed_rank_operator',
        st: 'standard_in',
        af: 'next_approaching_date_from',
        at: 'next_approaching_date_to',
        an: 'next_approaching_date_is_null',
        cf: 'contact_date_from',
        ct: 'contact_date_to',
        cn: 'contact_date_is_null',
        faf: 'first_appointment_datetime_from',
        fat: 'first_appointment_datetime_to',
        fan: 'first_appointment_datetime_is_null',
        laf: 'latest_appointment_datetime_from',
        lat: 'latest_appointment_datetime_to',
        lan: 'latest_appointment_datetime_is_null',
        flf: 'first_latest_appointment_datetime_from',
        flt: 'first_latest_appointment_datetime_to',
        fln: 'first_latest_appointment_datetime_is_null',
        fmf: 'first_making_appointment_date_from',
        fmt: 'first_making_appointment_date_to',
        fmn: 'first_making_appointment_date_is_null',
        lmf: 'latest_making_appointment_date_from',
        lmt: 'latest_making_appointment_date_to',
        lmn: 'latest_making_appointment_date_is_null',
        cef: 'certification_expiry_date_from',
        cet: 'certification_expiry_date_to',
        cen: 'certification_expiry_date_is_null',
        cs: 'contract_status',
        cp: 'campaign_number',
        aso: 'appointment_source_operator',
        as: 'appointment_source_in',
        cho: 'channel_operator',
        ch: 'channel_in',
        t: 'temperature',
        cot: 'count_type',
        o: 'orderby',
        df: 'display_fields',
        pg: 'page',
        pp: 'per_page',
    }

    static number_fields = [
        'department_operator',
        'contact_user_id',
        'follow_user_id',
        'sales_user_id',
        'area_id',
        'status_operator',
        'certainty_operator',
        'probability_operator',
        'rank_operator',
        'detailed_rank_operator',
        'next_approaching_date_is_null',
        'contact_date_is_null',
        'first_appointment_datetime_is_null',
        'latest_appointment_datetime_is_null',
        'first_latest_appointment_datetime_is_null',
        'first_making_appointment_date_is_null',
        'latest_making_appointment_date_is_null',
        'certification_expiry_date_is_null',
        'contract_status',
        'appointment_source_operator',
        'channel_operator',
        'temperature',
        'count_type',
        'orderby',
        'page',
        'per_page',
    ]

    static array_fields = [
        'department_in',
        'status_in',
        'certainty_in',
        'probability_in',
        'rank_in',
        'detailed_rank_in',
        'standard_in',
        'appointment_source_in',
        'channel_in',
        'display_fields',
    ]

    static toQuery(condition) {
        const query = {};

        Object.keys(condition).forEach((condition_key) => {
            for (const key in this.query_keys) {
                if (this.query_keys[key] === condition_key) {
                    if (Array.isArray(condition[condition_key])) {
                        query[key] = condition[condition_key] ? condition[condition_key].join(',') : '';
                    } else {
                        query[key] =  condition[condition_key] ?? '';
                    }
                }
            }
        });

        return query;
    }

    static fromQuery(query) {
        const condition = {};

        Object.keys(query).forEach((short_key) => {
            if (Object.keys(this.query_keys).includes(short_key)) {
                let long_key = this.query_keys[short_key]

                if (this.number_fields.includes(long_key)) {
                    condition[long_key] = query[short_key] ? Number(query[short_key]) : '';
                } else if(this.array_fields.includes(long_key)) {
                    condition[long_key] = query[short_key] && query[short_key] !== '' ? query[short_key].split(',').map(Number) : [];
                } else {
                    condition[long_key] = query[short_key];
                }
            }
        })

        return condition;
    }
}

export default SearchQueryService;